import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

// 获取租户分页列表
export function getTenantList() {
  return request({
    url: envInfo.bgApp.tenantPath + '/manager/findTenantList',
    method: 'get'
  });
}

// 查询租户扩展配置详情列表
export function getExtendConfigList(params){
    return request({
        url: envInfo.bgApp.tenantPath + '/extendConfig/getConfigList',
        method: 'get',
        params
    });
}

// 保存租户扩展配置
export function saveExtendConfig(params){
    return request({
        url: envInfo.bgApp.tenantPath + '/extendConfig/saveConfig',
        method: 'post',
        data: params
    });
}