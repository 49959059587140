<template>
  <div class="app-container-empty">
    <el-row>
      <el-col :style="{ width: 'calc(25% - 5px)' }">
        <el-row>
          <el-card class="extendConfigCard">
            <div slot="header" class="cardHeader">
              <el-row>
                <el-col>
                  <title-icon /><span>{{ $t("tenant.list") }}</span>
                </el-col>
              </el-row>
            </div>
            <template v-if="tenantList.length > 0">
              <div>
                <ul :class="{ ulStyle: isUl }" v-loading="loading">
                  <li
                    :class="{ liChosed: index == current }"
                    v-for="(item, index) in tenantList"
                    class="tenantLabel"
                    :title="item.tenantName"
                    :label="item.tenantName"
                    :value="item.tenantId"
                    :key="item.tenantId"
                    @click="showItDetail(item, index)"
                  >
                    {{ item.tenantName }}
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <div class="no-data">{{ $t("commons.noData") }}</div>
            </template>
          </el-card>
        </el-row>
      </el-col>
      <el-col :style="{ width: 'calc(75% - 5px)', marginLeft: '10px' }">
        <el-row>
          <el-card class="extendConfigCard">
            <div slot="header" class="cardHeader">
              <title-icon /><span>{{ configTitle }}</span>
            </div>
            <div>
              <el-form ref="form" :model="form">
                <el-row :gutter="20">
                  <el-col :span="24">
                    <el-form-item label="短信包剩余：">
                      <el-input-number
                        size="small"
                        :controls="false"
                        v-model="form.totalNumber"
                      ></el-input-number>
                      <span> 条 </span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="每日最多发送：">
                      <el-input-number
                        size="small"
                        :controls="false"
                        v-model="form.sendNumber"
                      ></el-input-number>
                      <span> 条 </span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item
                    label="当短信包余量少于："
                    style="display: flex; align-items: center; width: 100%"
                  >
                    <el-input-number
                      size="small"
                      :controls="false"
                      v-model="form.surplusWarningValue"
                    ></el-input-number>
                    <span> 条时，通知：</span>
                    <el-select
                      ref="userSelector"
                      @change="phoneChange"
                      v-model="warningPhone"
                      multiple
                      filterable
                      remote
                      allow-create
                      reserve-keyword
                      placeholder="请输电话号码"
                      :remote-method="remoteMethod"
                      :loading="loading"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="`${item.userName}(${item.phone})`"
                        :value="item.phone"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-row>
              </el-form>
              <el-row>
                <el-col>
                  <el-button type="primary" @click="saveExtendConfig">{{
                    $t("commons.save")
                  }}</el-button>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getTenantList } from "@/api/ruge/tenant/extendConfig";
import {
  queryInfoByTenantId,
  sendNumLimitEdit,
} from "@/api/ruge/tenant/tenant.js";
import { getUserList } from "@/api/ruge/vlink/rule/rule.js";
export default {
  name: "ExtendConfig",
  data() {
    return {
      options: [],
      resourceOptions: [],
      loading: false,
      current: 0,
      isTrue: true,
      isSpan: true,
      isUl: true,
      isLi: false,
      tenantList: [],
      configTitle: null,
      warningPhone: [],
      form: {
        tenantId: null,
        totalNumber: null,
        sendNumber: null,
        surplusWarningValue: null,
      },
    };
  },
  created() {
    // 查询租户列表
    this.getTenantList();
    this.getUserListHandler("", true);
  },
  methods: {
    getUserListHandler(query, initFlag) {
      getUserList({
        filter: query || "",
      })
        .then((res) => {
          this.options = res;
          if (initFlag) {
            console.log("init", this.warningPhone);
            this.resourceOptions = res;
            this.resourceEmailOptions = res;
            this.warningPhone.forEach((phone) => {
              if (!this.checkPhoneExistInResource(phone)) {
                this.resourceOptions.push({
                  userName: phone,
                  phone: phone,
                });
              }
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkPhoneExistInResource(phone) {
      for (let i = 0; i < this.resourceOptions.length; i++) {
        if (this.resourceOptions[i].phone === phone) {
          return true;
        }
      }
      return false;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getUserListHandler(query);
      } else {
        this.options = this.resourceOptions;
      }
    },
    phoneChange($event) {
      // this.form.phones = [];
      // 搜索完选择下拉项后清除掉输入内容
      this.$refs.userSelector.query = "";
      this.checkPhoneListValid();
    },
    checkPhoneValid(phoneNumber) {
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return reg_tel.test(phoneNumber);
    },
    checkPhoneListValid() {
      // 验证所有手机号码格式是否正确
      let phoneList = this.warningPhone;
      for (let i = 0; i < this.warningPhone.length; i++) {
        if (!this.checkPhoneValid(this.warningPhone[i])) {
          phoneList.splice(i, 1);
          this.$message.warning("手机号码格式不正确");
        }
      }
      console.log("yyyyyyy", this.warningPhone);
      // 到resourceOptions中查找，如果该手机号码没有，则创建一个option item
      phoneList.forEach((phone) => {
        if (!this.checkPhoneExistInResource(phone)) {
          this.resourceOptions.push({
            userName: phone,
            phone: phone,
          });
        }
      });
    },
    getTenantList() {
      this.loading = true;
      getTenantList()
        .then((res) => {
          // 移除平台管理租户
          // res.forEach(function (itme, index, array) {
          //   if (itme.tenantId === -1) {
          //     res.splice(index, 1);
          //   }
          // });
          this.tenantList = res;
          this.showItDetail(this.tenantList[0], 0);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 显示扩展配置详情
    showItDetail(obj, index) {
      if (obj != undefined) {
        queryInfoByTenantId(obj.tenantId).then((res) => {
          console.log("xxx", res);
          this.clearExtendConfig();
          this.configTitle = obj.tenantName;
          this.form.tenantId = obj.tenantId;
          this.current = index;
          if (res) {
            this.form.totalNumber = res.totalNumber || 0;
            this.form.sendNumber = res.sendNumber || 0;
            this.form.surplusWarningValue = res.surplusWarningValue || 0;
            this.warningPhone = res.warningPhones || [];
          }
        });
      }
    },
    // 保存租户扩展配置
    saveExtendConfig() {
      this.form.warningPhone = this.warningPhone.join(",");
      sendNumLimitEdit(this.form).then(() => {
        // 成功提示
        this.$message({
          message: this.$t("message.operationSuccess"),
          type: "success",
        });
      });
    },
    clearExtendConfig() {
      this.form = {
        tenantId: null,
        totalNumber: null,
        sendNumber: null,
        surplusWarningValue: null,
        warningPhone: [],
      };
    },
  },
};
</script>

<style scoped>
.extendConfigCard >>> .el-card__header {
  padding: 6px 10px;
}
.extendConfigCard >>> .el-card__body {
  height: calc(100vh - 140px);
  overflow: scroll;
}

.cardHeader {
  line-height: 34px;
  font-weight: 600;
}

.extendConfigCard >>> .el-form-item__content {
  padding-left: 20px;
}

.ulStyle {
  padding: 0;
  margin: 0;
}
.ulStyle li {
  list-style: none;
  line-height: 32px;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
}
.liChosed {
  background-color: #8bc1fc21;
  font-weight: 500;
  color: #409eff;
  border-radius: 5px;
}
.input {
  width: 100px;
}
.tenantLabel {
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>
